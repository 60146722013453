import { renderModal } from './ModalPlans';

window.addEventListener('load', async () => {
  const modals = document.getElementsByClassName('modal');

  const btnCardTvs = document.getElementsByClassName('CardTv-link');

  const closeModal = document.querySelector('.close');

  const planNames = document.getElementsByClassName(
    'modal-content-package-title'
  );

  const modal = document.querySelector('.modal');

  if (modal) {
    //verifica o data id se é igual ao do btn, percorrendo um array
    Array.from(btnCardTvs).map((btnCardTv) => {
      btnCardTv.onclick = function () {
        console.log('teste botao')
        Array.from(planNames).map(async (planName) => {
          planName.innerText =
            'Canais inclusos no ' + btnCardTv.getAttribute('data-name-plan');
          await renderModal(btnCardTv.getAttribute('data-name-plan'));
          jQuery('.modal-channel').hide();
          modal.style.display = 'block';

          jQuery('.modal-content-title').click(function (e) {
            e.preventDefault();

            let $this = jQuery(this);

            if ($this.next().hasClass('show')) {
              $this.removeClass('active-channel');
              $this.next().removeClass('show');
              $this.next().slideUp(350);
            } else {
              //  Adiciona função de fechar dropdown ao abrir outro
              //   $this.parent().parent().find('.modal-channel').removeClass('show');
              //   $this.parent().parent().find('.modal-channel').slideUp(350);
              $this.next().toggleClass('show');
              $this.toggleClass('active-channel');
              $this.next().slideToggle(350);
            }
          });
        });
      };
    });
  }

  //verifica o data id se é igual ao do modal, se for ele fecha
  if (closeModal) {
    closeModal.onclick = function () {
      modal.style.display = 'none';
    };
  }

  //Fecha modal ao clicar fora
  if (typeof modalTriggers !== 'undefined') {
    window.onclick = function (event) {
      if (
        !Array.from(modalTriggers).some((trigger) =>
          event.target.isEqualNode(trigger)
        )
      ) {
        Array.from(modals).map((modal) => {
          modal.style.display = 'none';
        });
      }
    };
  }
});
