window.addEventListener('load', async () => {
  const controllerButton = [].slice.call(document.getElementsByClassName('controllerButton'));
  const tabs = [].slice.call(document.getElementsByClassName('taball'))
  const tabLinks = [].slice.call(document.getElementsByClassName('tablinks'))
  const planos = [].slice.call(document.getElementsByClassName('planos'))
  const plansHome = [].slice.call(document.getElementsByClassName('PlansHome'))[0]
  const plansDetails = [].slice.call(document.getElementsByClassName('plansContainer'))
  const globoPlay = [].slice.call(document.getElementsByClassName('globoplay'))
  const roku = [].slice.call(document.getElementsByClassName('roku'))
  const DGO = [].slice.call(document.getElementsByClassName('directvgo'))
  const paramount = [].slice.call(document.getElementsByClassName('paramount'))
  const hboMax = [].slice.call(document.getElementsByClassName('hbo'))
  const premiere = [].slice.call(document.getElementsByClassName('premiere'))
  const globoplayMsg = [].slice.call(document.getElementsByClassName('globoplaymsg'))[0]
  const internet = [].slice.call(document.getElementsByClassName('internet'))[0]
  const allAccordion = [].slice.call(document.getElementsByClassName('grid-accordion'))
  const globoPlayCanais = [].slice.call(document.getElementsByClassName('globoplaycanais'))
  const telecine = [].slice.call(document.getElementsByClassName('telecine'))

  if(internet){
      internet.style.display = 'flex'
  }

  controllerButton.forEach((botao, index) => {
      botao.addEventListener('click', function(){
          allAccordion.forEach(item => item.style.display = 'none')
          globoplayMsg.style.display = 'none';
          controllerButton.forEach((item) => {
              item.classList.remove('active')
          })
          if(botao == this){
              botao.classList.add('active')
          }
          tabLinks.forEach(item => {
              item.classList.remove('active')
          })
          tabs.forEach(item => item.classList.remove('active'))
          tabs[index].classList.add('active')
          planos.forEach(item => item.classList.remove('active'))
          plansDetails.forEach((planDetail) => planDetail.style.display = 'none')
          allAccordion.forEach(item => item.style.display = 'none')
          if(index == 0){
              tabLinks[0].classList.add('active')
              planos[0].classList.add('active')
              internet.style.display = 'flex'
          }else{
              tabLinks[1].classList.add('active')

              planos.forEach(item => {
                  if(item?.dataset?.plan == tabLinks[1]?.dataset?.plan){
                      item.classList.add('active')
                  }
              })
              // DGO.style.display = 'flex';
              // DGO.forEach(item => item.style.display = 'flex')
              switch(tabLinks[1]?.dataset?.plan){
                  case 'dgo':
                      DGO.forEach(item => item.style.display = 'flex');
                  break;
                  case 'roku':
                      roku.forEach(item => item.style.display = 'flex');
                  break;
                  case 'globoplay':
                      globoPlay.forEach(item => item.style.display = 'flex');
                      globoplayMsg.style.display = 'block';
                  break;
                  case 'paramount':
                      paramount.forEach(item => item.style.display = 'flex');
                  break;
                  case 'hbo':
                      hboMax.forEach(item => item.style.display = 'flex');
                  break;
                  case 'premiere':
                      premiere.forEach(item => item.style.display = 'flex');
                  break;
              }
          }
      })
  })

  tabLinks.forEach((tab, index) => {

      if(index==1 && tab.classList.contains('active') && DGO){
          DGO.forEach(item => item.style.display = 'flex');
      }

      tab.addEventListener('click', function(){
          tabLinks.forEach(item => item.classList.remove('active'))
          this.classList.add('active')
          planos.forEach(item => item.classList.remove('active'))

          if(index == 0){
              planos[0].classList.add('active')
          }else{
              planos.forEach(item => {
                  if(item?.dataset?.plan == tab?.dataset?.plan){
                      item.classList.add('active')
                  }
              })
          }

          globoplayMsg.style.display = 'none';

          allAccordion.forEach(item => item.style.display = 'none')
          plansDetails.forEach((planDetail) => planDetail.style.display = 'none')
          if(tab?.dataset?.plan){
              switch(tab.dataset.plan){
                  case 'dgo':
                      DGO.forEach(item => item.style.display = 'flex');
                  break;
                  case 'roku':
                      roku.forEach(item => item.style.display = 'flex');
                  break;
                  case 'globoplay':
                      globoPlay.forEach(item => item.style.display = 'flex');
                      globoplayMsg.style.display = 'block';
                  break;
                  case 'paramount':
                      paramount.forEach(item => item.style.display = 'flex');
                  break;
                  case 'hbo':
                      hboMax.forEach(item => item.style.display = 'flex');
                  break;
                  case 'premiere':
                      premiere.forEach(item => item.style.display = 'flex');
                  break;
                  case 'globoplaycanais':
                      globoPlayCanais.forEach(item => item.style.display = 'flex');
                  break;
		 case 'telecine':
                      telecine.forEach(item => item.style.display = 'flex');
                  break;
              }
          }
      })
  })

  let contador = 0

  planos.forEach((item, index) => {
      const CardPlan = [].slice.call(item.getElementsByClassName('CardPlan'))
      if(CardPlan.length == 0){
          item.style.display = 'none'
          tabLinks[index].style.display = 'none'
      }else{
          contador++
      }
  })

  // if(contador == 0){
  //     plansHome.style.display = 'none'
  // }
})
