import 'regenerator-runtime/runtime'

import './blocks/scripts/_js/hooks/UseNormalizeText'

import './blocks/scripts/_js/redirect'
import './blocks/scripts/_js/masks'
import './blocks/scripts/_js/searchBar'
import './blocks/scripts/_js/locatesSelect'
import './blocks/scripts/_js/swiper'

import './blocks/scripts/about-us-cards/section/read-more'
import './blocks/scripts/about-us-cards/section/swiper'
import './blocks/scripts/advantages/swiper'
import './blocks/scripts/form-cupom/form'
import './blocks/scripts/cards-dropdown/drop'
import './blocks/scripts/plans-api/swiper'

import './blocks/scripts/plans-company/swiper'
import './blocks/scripts/plans-corporate/swiper'
import './blocks/scripts/plans-no-tabs/swiper'
import './blocks/scripts/plans-tabs/ModalPlans'
import './blocks/scripts/plans-tabs/plans-tv'
import './blocks/scripts/plans-tabs/swiper'
import './blocks/scripts/plans-tabs/swiperComboTv'
import './blocks/scripts/plans-tabs/tabs-plans'
import './blocks/scripts/solution-section/swiper'
import './blocks/scripts/tabs/functions/actions'
import './blocks/scripts/tabs-sumicity/functions/changeTab'
import './blocks/scripts/tabs-company/tab'


import './blocks/scripts/plans-all-services/swiper'
import './blocks/scripts/plans-all-services/botoes'
import './blocks/scripts/plans-all-services/plans-tv'
import './blocks/scripts/plans-all-services/ModalPlans'
import './blocks/scripts/plans-all-services/modalForms'